// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-demographics-table-tsx": () => import("./../src/pages/demographics-table.tsx" /* webpackChunkName: "component---src-pages-demographics-table-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-table-tsx": () => import("./../src/pages/industry-table.tsx" /* webpackChunkName: "component---src-pages-industry-table-tsx" */),
  "component---src-pages-occupation-table-tsx": () => import("./../src/pages/occupation-table.tsx" /* webpackChunkName: "component---src-pages-occupation-table-tsx" */),
  "component---src-pages-program-table-tsx": () => import("./../src/pages/program-table.tsx" /* webpackChunkName: "component---src-pages-program-table-tsx" */)
}

